import React from 'react'
import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline'

const features = [
  { name: 'CI/CD', icon: CloudUploadIcon, desc: "We can intergrate with existing CI/CD pipelines or build one for you." },
  { name: 'Security', icon: LockClosedIcon, desc: "Security is never far from our mind. Your task may be simple or complex, we always emphasise security." },
  { name: 'Workflow', icon: RefreshIcon, desc: "We can help decouple your appliction to take advantge of microservice architecture and serverless technologies." },
  { name: 'Trusted Partner', icon: ShieldCheckIcon, desc: "Our engagement can be short, or we can develop our relationship into a lasting partnership. We're always here for you." },
  { name: 'Configuration and Support', icon: CogIcon, desc: "You can engage us for small components or we can help configure and support large developments." },
  { name: 'Data Integration', icon: ServerIcon, desc: "Data can come from anywhere. We're comfortable sourcing and integrating data from multiple sources." },
]

const Feature = () => {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">Build faster</h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Everything you need to build or deploy your app.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.desc}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feature
