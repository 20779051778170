import React from 'react'
import { Link } from 'gatsby'
import { ExternalLinkIcon } from '@heroicons/react/solid'

const SampleWork = () => {
  return (
    <div className="relative bg-indigo-600">
      <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1552581234-26160f608093?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
          alt=""
        />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">Our work speaks for itself</p>
          <p className="mt-3 text-lg text-gray-300">
            The best way to know what we're capable of is to take a look at some samples. We've compiled some sample work at the link below that encompasses a broad range of our skill sets.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <Link to="/samplework"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                See our work!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SampleWork
