import React from 'react'
import Hero from '../components/Hero'
import Feature from '../components/Feature'
import SampleWork from '../components/SampleWork'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import Header from '../components/Header'

const Index = () => {
  return (
    <>
      <Header />
      <Hero />
      <SampleWork />
      <Feature />
      <ContactUs />
      <Footer />
    </>
  )
}

export default Index
